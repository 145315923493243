import React from 'react';
import { w3cwebsocket as WebSocket } from 'websocket';
import { List, Avatar, Layout, Row, Col, Badge, Icon } from 'antd';
import { getDummyData } from './dummyData';
import moment from 'moment';

import 'antd/dist/antd.css';
import './dashboard.css';

// import '../public/images/'

const { Content } = Layout;

class Dashboard extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      records: {},     
      liveFlows: [],
      xepstResponses: [],
    }
    this._inputRef = null;
    this.wsc = null
    this.connectToWsServer = this.connectToWsServer.bind(this)
  }

  componentDidMount() {
    this.connectToWsServer();
  }

  connectToWsServer() {
    const webScocket = process.env.REACT_APP_WEBSOCKET||'ws://10.0.1.142:30088'
    this.wsc = new WebSocket(webScocket, 'echo-protocol')
    // this.wsc = new WebSocket('ws://10.99.99.29:8080', 'echo-protocol')

    this.wsc.onopen = () => {
      console.log('Connected to Backend..')
      this.wsc.send('hello from react')

      const showDummyData = false;
      if(showDummyData){
        const dummyData = getDummyData("OUT");
        const dummyData2 = getDummyData("IN");
        // this.getPic(dummyData.recordMap.xepstResponses);
        // this.getPic(dummyData2.recordMap.xepstResponses);
        this.setState({
            records: Object.assign({ [`${dummyData.mode}_${dummyData.cardId}`]: dummyData.recordMap }, this.state.records)
        })
        this.setState({
            records: Object.assign({ [`${dummyData2.mode}_${dummyData2.cardId}`]: dummyData2.recordMap }, this.state.records)
        })
        }
      }      
    this.wsc.onmessage = (data) => {
      try{
        const jsonData = JSON.parse(data.data);
        const log = JSON.parse(jsonData.data);
        const eventType = jsonData.type;
        let cardId;
        let mode = null
        if (eventType === 'employee_flow') {
          mode = log.mode;
          cardId = log.cardId
        }
        if (eventType === 'xepst_response') {
          mode = log.request.mode;
          cardId = log.request.cardId
        }
        const recordsMap = this.state.records;
        const recordMap = recordsMap[`${mode}_${cardId}`] || {};
        if (jsonData.type === 'echo') {
          console.log('Echo:', jsonData.data)
        } else if (jsonData.type === 'employee_flow') {
          const { liveFlows } = this.state
          recordMap.employee_flow = {
            type: jsonData.type,
            ...JSON.parse(jsonData.data)
          }
        } else if (jsonData.type === 'xepst_response') {
          const { xepstResponses } = this.state
          const data = JSON.parse(jsonData.data)
          const { Employee } = data.response
          if (Employee) {
            recordMap.xepstResponses = {
              ...data,
              type: jsonData.type,
              added: moment().utcOffset('+0545').format('dddd, MMMM Do, YYYY h:mm:ss A Z')
            };
          }
        } else {
          // console.log(jsonData)
        }
        if (jsonData.type !== 'echo' && cardId) {
          this.setState({
            records: Object.assign({ [`${mode}_${cardId}`]: recordMap }, this.state.records)
          })
        }
      }
      catch(e){
        // console.log("error");
        // console.log(e);
      }
    }

    this.wsc.onclose = (e) => {
      console.log('Socket is closed. Reconnect will be attempted in 4 second.', e.reason)
      setTimeout(this.connectToWsServer, 4000)
    }

    this.wsc.onerror = (err) => {
      console.error('Socket encountered error: ', err.message, 'Closing socket')
      this.wsc.close()
    }
  }
  copyThis = (text) => {
    return () => {
      if(!this._inputRef) return;
      this._inputRef.value = text;
      this._inputRef.select();
      document.execCommand('copy')
  }
  }
  getTodayString = () => {
    let nepalTime = new Date().toLocaleString('en-US', { timeZone: 'Asia/Kathmandu' })
    nepalTime = new Date(nepalTime)

    let day = nepalTime.getDate()
    let month = nepalTime.getMonth() + 1

    if (day < 10) day = '0' + day
    if (month < 10) month = '0' + month
    const today = `${month}-${day}`
    return today
  }
  renderFeed(item) {
    if (!item || !item.xepstResponses) {
      return  <div/>
    }
    const { request, response } = item.xepstResponses;
    
    const Employee = response.Employee.Employee[0]
    const today = this.getTodayString()

    const { Birthday, Begindate } = Employee
    const birthday = Birthday ? Birthday.slice(5) === today : false
    const anniversary = Begindate ? Begindate.slice(5) === today : false
    const employeeFlow = item.employee_flow && item.employee_flow.mode == "IN" ? "flowIn" : "flowOut"

    return <List.Item>
      <div className={`employeeCard ${employeeFlow} ${anniversary && 'anniversaryMainDiv'} ${birthday && 'birthdayMainDiv'}`}>
        {/* <p>
{item.employee_flow && item.employee_flow.cardId}
</p>
<p>
{item.employee_flow && item.employee_flow.mode}
</p>
<p>
{item.employee_flow && item.employee_flow.timestamp}
</p> */}
        <div className="abc">
        <div className="employeeInfo">
            {this.renderFeeds(item)}


          </div>
          <div className="employeeRecordMain">
          <div className="employeeRecord">
<span className='recordTime'>
            {moment.unix(item.employee_flow.timestamp).utcOffset('+0545').format('h:mm:ss A')}
            </span>
            <span className='recordDate'>
            {moment.unix(item.employee_flow.timestamp).utcOffset('+0545').format('dddd, MMMM Do, YYYY  ')}
            </span>
          </div>

          <div className="cardNumber">
            {item.employee_flow && item.employee_flow.cardId}
            {item.employee_flow && <button className="copyNumber" onClick={this.copyThis(item.employee_flow.cardId)}><Icon type="copy" theme="filled" /></button>}

          </div>
          </div>

          <div className="employeeCardDetail">

            <p>
              <span>
                {item.employee_flow && item.employee_flow.mode}
              </span>
            </p>
          </div>

          {/* <div className="employeeCardDetail">
{item.employee_flow && item.employee_flow.cardId}
<p>
{item.employee_flow && item.employee_flow.mode}
</p>
</div> */}
        </div>
      </div>
    </List.Item>
  }
  renderFeeds(item) {
    if (!item || !item.xepstResponses) {
      return (
        <p>Something went wrong</p>
      )
    }
    const { request, response, url } = item.xepstResponses;
    const Employee = response.Employee.Employee[0]

    const today = this.getTodayString()

    const { Birthday, Begindate } = Employee
    const birthday = Birthday ? Birthday.slice(5) === today : false
    const anniversary = Begindate ? Begindate.slice(5) === today : false
    return (
      <List.Item>
        <List.Item.Meta
          avatar={
            (
              () => {
                const imgUrls = Employee.Number+'.png'
                const imgUrl = '/images/' + imgUrls
                  if (birthday) {
                    return (
                      <div>
                        <Badge count={<Icon type='crown' style={{ color: '#f7be16' }} theme='filled' />}>
                          <Avatar
                            src = {imgUrl}
                            onError = { (e)=> true }
                            icon={request.mode === 'IN' ? 'user-add' : 'user-delete'}
                            style={{
                              backgroundColor: request.mode === 'IN' ? '#87d068' : '#f56a00',
                              verticalAlign: 'middle'
                            }}
                            size='large'
                          />
                        </Badge>
                        <p className="employeeBirthday">Happy Birthday</p>
                      </div>
                    )
                  } else if (anniversary) {
                    return (
                      <div>
                        <Badge count={<Icon type='star' style={{ color: '#f7be16' }} theme='filled' />}>
                          <Avatar
                            src = {imgUrl}
                            onError = { (e)=> true }
                            icon={request.mode === 'IN' ? 'user-add' : 'user-delete'}
                            style={{
                              backgroundColor: request.mode === 'IN' ? '#87d068' : '#f56a00',
                              verticalAlign: 'middle'
                            }}
                            size='large'
                          />
                        </Badge>
                        <p className="employeeAnniversary">Happy Work Anniversary</p>
                      </div>
                    )
                  }
                  else{
                    return(
                      <Avatar
                          src = {imgUrl}
                          onError = { (e)=> true }
                          icon={request.mode === 'IN' ? 'user-add' : 'user-delete'}
                          style={{
                            backgroundColor: request.mode === 'IN' ? '#87d068' : '#f56a00',
                            verticalAlign: 'middle'
                          }}
                          size='large'
                      />
                    )
                  }
                }
            )()
          }
          title={
            <React.Fragment>
              <span className='ItemTitle'>{Employee.Name} </span>
              <span className="employeeDesignation">{Employee.Designation}</span>
            </React.Fragment>
          }
          description={
            <span>
              {item.added} <b>{request.location}</b>
            </span>
          }
        />
      </List.Item>
    )
  }
  render() {
    const today = this.getTodayString()
    // console.log(today)
    return (
      <Layout
        style={{
          width: '100%',
          height: '100vh',
          alignContent: 'center',
          backgroundColor: '#282c34',
          color: 'white',
          minHeight: '100vh'
        }}
        className='DashboardLayout'
      >
        <Content className='MainContainer'>
        <input className="cardInput" type="text" ref={el => this._inputRef =el} />
          <Row type="flex" justify="center" align="top">
            <Col span={16}>
              <List
                className='EmployeeList latestRecord'
                // header={<h2 style={{ color: '#282c34' }}>Live Events</h2>}
                itemLayout='vertical '
                grid={{ gutter: 16, column: 1 }}
                dataSource={Object.values(this.state.records)} 
                renderItem={item => {
                  if (!item) return null;
                  return this.renderFeed(item);
                }}
              />
            </Col>
            <Col span={8}>
              <List
                className='EmployeeList remainingRecord'
                // header={<h2 style={{ color: '#282c34' }}>Live Events</h2>}
                itemLayout='vertical '
                grid={{ gutter: 16, column: 1 }}
                dataSource={Object.values(this.state.records)} 
                renderItem={item => {
                  if (!item) return null;
                  return this.renderFeed(item);
                }}
              />
            </Col>
          </Row>
        </Content>
      </Layout>
    )
  }
}

export default Dashboard