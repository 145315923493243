import React from 'react'
import Dashboard from './Dashboard'

function App () {
  return (
    <div className='App'>
      <Dashboard />
    </div>
  )
}

export default App
